import { Helmet } from 'react-helmet-async';
import ModifiersView from 'src/sections/modifiers/view/modifiers-view';

// ----------------------------------------------------------------------

export default function OverviewModifiersPage() {
  return (
    <>
      <Helmet>
        <title> Modifiers</title>
      </Helmet>
      <ModifiersView />
    </>
  );
}
