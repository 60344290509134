import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Container,
  Stack,
  Divider,
  Drawer,
  Grid,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import { FORMDETAILS } from 'src/_mock/_formdetails';
import { AVAILABILITY } from 'src/_mock/_availability';
import { ColorPicker } from 'src/components/color-utils';
import { COLOR_OPTIONS } from 'src/_mock/_calendar';
import { palette as themePalette } from 'src/theme/palette';
import { useSnackbar } from 'src/components/snackbar';
import { createMenu, editMenu, useGetAllGroups, useGetAllPrepStations } from 'src/api/apis';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';
import {
  filterMyGroup,
  removeSaveMyGroup,
  removeSaveMyItem,
  replaceMyGroup,
  saveMyGroup,
} from 'src/features/group/groupSlice';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { add, format, isBefore, parse } from 'date-fns';
import Iconify from '../iconify';
import { RHFSelect, RHFTextField, RHFMultiCheckbox, RHFSwitch } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
import AddGroup from '../add-group/add-group';
import Scrollbar from '../scrollbar';

const CreateMenu = ({
  currentMenu,
  pathname,
  drawerstatus,
  closeMenu,
  breadcrumb,
  Menuprops,
  edit,
  flow,
}) => {
  const MenuSchema = Yup.object().shape({
    menuName: Yup.string().required('menuName is required'),
    availability: Yup.bool().required('availability is required'),
    // nickName: Yup.string(),
    color: Yup.string(),
    // taxRates: Yup.array().min(1, 'Choose at least one option'),
    taxRates: Yup.array(),
    calculatedPrice: Yup.bool(),
    taxException: Yup.bool(),
    // kitchenNickName: Yup.string(),
    // kdsColor: Yup.string(),
    prepStations: Yup.array().min(1, 'Choose at least one option'),
    // course: Yup.string(),
    pos: Yup.bool(),
    kiosk: Yup.bool(),
    onlineOrder: Yup.bool(),
    menuGroups: Yup.array(),
    schedule: Yup.array()
      .of(
        Yup.object().shape({
          day: Yup.number().required('Day is required'),
          open: Yup.string()
            .required('Open time is required')
            .test('Open time is required', (value) => value.trim() !== ''),
          close: Yup.string()
            .required('Close time is required')
            .test('Close time is required', (value) => value.trim() !== ''),
        })
      )
      .min(1, 'At least one day should be selected'),
  });

  const savedgroup = useSelector((state) => state.group.savedgroup);

  const palette = themePalette('light');
  const [showGroupOption, setshowGroupOption] = useState(true);
  const [menuGroups, setMenuGroups] = useState([]);
  const [menuFlow, setFlow] = useState(true);
  const [editOption, seteditOption] = useState(false);
  const selectedObjectRef = useRef(null);
  const [ItemList, setItemList] = useState([]);
  const [selectedModifiers, setselectedModifiers] = useState([]);
  const dispatchedModifierRef = useRef(new Set());
  const [loading, setLoading] = useState(false);

  // Api handling
  const { data: prep } = useGetAllPrepStations();
  const { data: groups } = useGetAllGroups();

  const updatedPrepStations = prep?.map((item) => ({
    value: item.prepStationId,
    label: item.description,
    prepStation: item.item,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    if (flow === false) {
      setFlow(false);
    }
    if (edit) {
      setMenuGroups(Menuprops?.menuGroups);
      // eslint-disable-next-line array-callback-return
      // menuGroups?.map((item, index) => {
      //   item.calculatedPrice = true;
      // });
    }
  }, [menuGroups, edit, Menuprops?.menuGroups, flow]);

  useEffect(() => {
    // dispatch(removeModifier());
    if (groups != null || groups !== undefined) {
      setItemList(groups);
    }
  }, [groups]);

  const handleAutocompleteChange2 = (event, newValue, reason, detail) => {
    if (reason === 'removeOption') {
      if (dispatchedModifierRef.current.has(detail.option.menuGroupId)) {
        dispatchedModifierRef.current.delete(detail.option.menuGroupId);
      }
    } else if (reason === 'clear') {
      dispatchedModifierRef.current.clear();
    }
    setselectedModifiers(newValue);
    newValue.forEach((item) => {
      if (!dispatchedModifierRef.current.has(item.menuGroupId)) {
        dispatch(saveMyGroup(item));
        dispatchedModifierRef.current.add(item.menuGroupId);
      }
    });
  };
  // Delete Item Option
  const deleteFunc = (item) => {
    dispatchedModifierRef.current.delete(item.menuGroupId);
    dispatch(filterMyGroup(item.menuGroupId));
  };
  const defaultValues = useMemo(() => {
    if (edit && Menuprops) {
      return {
        menuName: Menuprops?.menuName || '',
        availability: Menuprops?.availability || false,
        nickName: Menuprops?.nickName || '',
        prepStations: Menuprops?.prepStations || [],
        pos: Menuprops?.pos || false,
        kiosk: Menuprops?.pos || false,
        onlineOrder: Menuprops?.onlineOrder || false,
        menuGroups: Menuprops?.menuGroups || [],
        schedule: Menuprops?.schedule || [],
      };
      // eslint-disable-next-line no-else-return
    } else {
      return {
        menuName: currentMenu?.menuName || '',
        availability: currentMenu?.availability || false,
        // nickName: currentMenu?.nickName || '',
        color: currentMenu?.color || '',
        // taxRates: currentMenu?.taxRates || [],
        // calculatedPrice: currentMenu?.calculatedPrice || false,
        // taxException: currentMenu?.taxException || false,
        // kitchenNickName: currentMenu?.kitchenNickName || '',
        // kdsColor: currentMenu?.kdsColor || '',
        prepStations: currentMenu?.prepStations || [],
        // course: currentMenu?.course || '',
        pos: currentMenu?.pos || false,
        kiosk: currentMenu?.kisok || false,
        onlineOrder: currentMenu?.onlineOrder || false,
        menuGroups: currentMenu?.menuGroups || [],
        schedule: currentMenu?.schedule || [],
      };
    }
  }, [edit, currentMenu, Menuprops]);
  const methods = useForm({
    resolver: yupResolver(MenuSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    // formState: { isSubmitting },
    setValue,
    watch,
    reset,
  } = methods;
  const values = watch();

  const handleAvailability = (e) => {
    const day = parseInt(e.target.name, 10); // Get the `day` value from the checkbox name
    const isChecked = e.target.checked;

    // Get the current schedule array
    const currentSchedule = values.schedule || [];

    let updatedSchedule = [...currentSchedule];

    if (isChecked) {
      // Add the day if it doesn't already exist
      if (!updatedSchedule.some((item) => item.day === day)) {
        updatedSchedule.push({ day, open: '', close: '' });
      }
    } else {
      // Remove the day if it exists
      updatedSchedule = updatedSchedule.filter((item) => item.day !== day);
    }

    // Update the schedule array in the form state
    setValue('schedule', updatedSchedule);
  };
  const handleOpenTime = (newValue, day) => {
    const formattedTime = format(new Date(newValue), 'HH:mm:ss');
    const updatedSchedule = values.schedule.map((item) =>
      item.day === day ? { ...item, open: formattedTime } : item
    );
    setValue('schedule', updatedSchedule);
  };

  const handleCloseTime = (newValue, day) => {
    let updatedSchedule;
    const formattedCloseTime = format(new Date(newValue), 'HH:mm:ss');

    // Find the current day's schedule
    const daySchedule = values.schedule.find((item) => item.day === day);

    if (daySchedule) {
      const openTime = parse(daySchedule.open, 'HH:mm:ss', new Date());
      const closeTime = parse(formattedCloseTime, 'HH:mm:ss', new Date());

      // Validate that closeTime is not earlier than openTime
      if (isBefore(closeTime, openTime)) {
        alert('Close time must be later than open time.');
        updatedSchedule = values.schedule.map((item) =>
          item.day === day ? { ...item, close: '' } : item
        );
        setValue('schedule', updatedSchedule);
        return;
      }
    }

    // Update the schedule if validation passes
    updatedSchedule = values.schedule.map((item) =>
      item.day === day ? { ...item, close: formattedCloseTime } : item
    );
    setValue('schedule', updatedSchedule);
  };

  const handleMinTime = (day) => {
    const minTime = values?.schedule?.find((item) => item.day === day)?.open
      ? add(parse(values.schedule.find((item) => item.day === day).open, 'HH:mm:ss', new Date()), {
          hours: 1,
        })
      : null;
    return minTime;
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Set default values when component is mounted
    reset(defaultValues);
    if (edit && Menuprops?.menuGroups !== null && Menuprops?.menuGroups !== undefined) {
      dispatch(replaceMyGroup(Menuprops?.menuGroups));
    }
  }, [reset, defaultValues, edit, dispatch, Menuprops?.menuGroups]);

  // const deleteMenuCallback = useCallback(async () => {
  //   try {
  //     await deleteMenu(Menuprops.menuId);
  //     closeMenu(false);
  //   } catch (error) {
  //     console.error(`Failed to delete menu with id ${menuGroups[0].menuGroupId}:`, error);
  //   }
  // }, [Menuprops, closeMenu, menuGroups]);

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    let mydata;
    try {
      mydata = { ...data, menuGroups };
      if (flow && savedgroup.length > 0) {
        const extractedIds = savedgroup.map((item, index) => ({
          menuGroupId: item.menuGroupId,
          position: index,
        }));
        const nonNullIds = extractedIds.filter(
          (item) => item.menuGroupId !== null && item.menuGroupId !== undefined
        );
        console.log('nonNullIds', nonNullIds);
        mydata = { ...data, menuGroups: nonNullIds };
      }

      if (flow === false && edit) {
        const idArray = Menuprops.menuGroups.map((item, index) => ({
          menuGroupId: item.menuGroupId,
          position: item.position || index,
        }));
        console.log('idArray', idArray);
        mydata = { ...data, menuGroups: idArray };
      }
      let res;
      if (edit) {
        const id = Menuprops?.menuId;
        res = await editMenu(id, mydata);
      } else {
        res = await createMenu(mydata);
      }
      dispatch(removeSaveMyItem());
      dispatch(removeSaveMyGroup());
      dispatchedModifierRef.current.clear();
      setselectedModifiers([]);
      reset();
      if (res.status === 200) {
        enqueueSnackbar('changes successfully added!', { variant: 'success' });
      } else if (res.status !== 200 || res.status === 404) {
        enqueueSnackbar('Unable to save the changes', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    handleCloseDrawer();
    setLoading(false);
  });

  // Drawer
  const [openGroupDrawer, setopenGroupDrawer] = useState(false);
  const breadcrumblist = [
    ...breadcrumb,
    {
      name: 'Create Category',
      href: './',
    },
  ];

  const handleopenGroupDrawer = useCallback(() => {
    setopenGroupDrawer(true);
  }, []);
  const openGroup = useCallback(() => {
    seteditOption(false);
    setshowGroupOption(true);
    selectedObjectRef.current = { menuItems: [] };
    setFlow(true);
    setshowGroupOption(false);
    handleopenGroupDrawer();
    dispatch(removeSaveMyItem());
  }, [dispatch, handleopenGroupDrawer]);

  const OpenEditGroup = useCallback(
    (item) => {
      setFlow(true);
      seteditOption(true);
      selectedObjectRef.current = item;
      handleopenGroupDrawer();
    },
    [handleopenGroupDrawer]
  );

  useEffect(() => {
    if (drawerstatus === true) {
      setopenGroupDrawer(true);
    } else {
      setopenGroupDrawer(false);
    }
    if (openGroupDrawer) {
      handleCloseGroupDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleCloseDrawer = () => {
    closeMenu(false);
  };

  const handleCloseGroupDrawer = () => {
    setshowGroupOption(false);
    setopenGroupDrawer(false);
  };
  const handleCloseGroupDrawer2 = useCallback((val) => {
    setopenGroupDrawer(val);
    setopenGroupDrawer(false);
    mutate(endpoints.menuGroup.menu);
  }, []);

  const createMenuRef = useRef(null);

  const scrollToSection = useCallback((section) => {
    if (createMenuRef.current) {
      const targetSection = createMenuRef.current.querySelector(`#${section.replace(/ /g, '-')}`);
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });

        // Check if the last item is visible and adjust the scroll
        const lastItem = FORMDETAILS[FORMDETAILS.length - 1];
        const lastItemElement = createMenuRef.current.querySelector(
          `#${lastItem.replace(/ /g, '-')}`
        );
        const containerBottom = createMenuRef.current.getBoundingClientRect().bottom;
        const lastItemBottom = lastItemElement.getBoundingClientRect().bottom;

        if (lastItemBottom > containerBottom) {
          createMenuRef.current.scrollTo({
            top: createMenuRef.current.scrollTop + (lastItemBottom - containerBottom),
            behavior: 'smooth',
          });
        }
      }
    }
  }, []);

  /// container functions
  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3, marginTop: '20px' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600px', color: `grey.500` }}>
          General Info{' '}
        </Typography>
      </Box>

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Menu Name</Typography>
        <RHFTextField name="menuName" label="Menu Name" />
      </Stack>
      <Stack spacing={1.5} sx={{ my: 2, width: '300px' }}>
        <RHFSelect
          native
          name="availability"
          label="Availability"
          InputLabelProps={{ shrink: true }}
        >
          {AVAILABILITY?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Pos nickname (if different than item)</Typography>
        <RHFTextField name="nickName" label="Pos nickname (if different than item)" />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> pos Color Button</Typography>
        <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack>
    </Stack>
  );

  const Availability = (
    <Stack
      id="Availability"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3, marginTop: '20px' }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600px', color: `grey.500` }}>
          Availability{' '}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 5,
          // px: 8,
          borderBottom: '2px solid', // Adds a border at the bottom
          borderColor: 'grey.300',
        }}
      >
        <Box sx={{ width: '60%', display: 'flex', justifyContent: 'start', ml: 2 }}>Days</Box>
        <Box sx={{ width: '100%' }}>To</Box>
        <Box sx={{ width: '100%' }}>From</Box>
      </Box>

      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              name="schedule"
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 1)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={1}
                />
              }
              label="Sunday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 1)}
              value={
                values?.schedule?.find((item) => item.day === 1)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 1).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 1;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 1)}
              minTime={handleMinTime(1)}
              value={
                values?.schedule?.find((item) => item.day === 1)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 1).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 1;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 2)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={2}
                />
              }
              label="Monday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 2)}
              value={
                values?.schedule?.find((item) => item.day === 2)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 2).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 2;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 2)}
              minTime={handleMinTime(2)}
              value={
                values?.schedule?.find((item) => item.day === 2)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 2).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 2;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 3)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={3}
                />
              }
              label="Tuesday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 3)}
              value={
                values?.schedule?.find((item) => item.day === 3)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 3).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 3;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              minTime={handleMinTime(3)}
              disabled={!values?.schedule?.some((item) => item.day === 3)}
              value={
                values?.schedule?.find((item) => item.day === 3)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 3).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 3;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 4)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={4}
                />
              }
              label="Wednesday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 4)}
              value={
                values?.schedule?.find((item) => item.day === 4)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 4).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 4;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              minTime={handleMinTime(4)}
              disabled={!values?.schedule?.some((item) => item.day === 4)}
              value={
                values?.schedule?.find((item) => item.day === 4)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 4).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 4;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 5)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={5}
                />
              }
              label="Thursday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 5)}
              value={
                values?.schedule?.find((item) => item.day === 5)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 5).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 5;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              minTime={handleMinTime(5)}
              disabled={!values?.schedule?.some((item) => item.day === 5)}
              value={
                values?.schedule?.find((item) => item.day === 5)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 5).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 5;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 6)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={6}
                />
              }
              label="Friday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 6)}
              value={
                values?.schedule?.find((item) => item.day === 6)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 6).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 6;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              minTime={handleMinTime(6)}
              disabled={!values?.schedule?.some((item) => item.day === 6)}
              value={
                values?.schedule?.find((item) => item.day === 6)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 6).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 6;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.schedule?.some((item) => item.day === 7)}
                  onChange={(e) => {
                    handleAvailability(e);
                  }}
                  name={7}
                />
              }
              label="Saturday"
            />
            <DesktopTimePicker
              disabled={!values?.schedule?.some((item) => item.day === 7)}
              value={
                values?.schedule?.find((item) => item.day === 7)?.open
                  ? parse(
                      values.schedule.find((item) => item.day === 7).open,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 7;
                handleOpenTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
            <DesktopTimePicker
              minTime={handleMinTime(7)}
              disabled={!values?.schedule?.some((item) => item.day === 7)}
              value={
                values?.schedule?.find((item) => item.day === 7)?.close
                  ? parse(
                      values.schedule.find((item) => item.day === 7).close,
                      'HH:mm:ss',
                      new Date()
                    )
                  : null
              }
              onChange={(newValue) => {
                const day = 7;
                handleCloseTime(newValue, day);
              }}
              sx={{
                width: { xs: '234px', sm: 'auto' },
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
            />
          </Box>
        </FormGroup>
      </FormControl>
    </Stack>
  );
  // const PricingTaxes = (
  //   <Stack
  //     id="Pricing-and-Taxes"
  //     sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
  //   >
  //     <Typography sx={{ color: `grey.500` }}>Pricing and Taxes </Typography>
  //     <Stack spacing={1.5} sx={{ my: 2 }}>
  //       <Typography variant="subtitle2">Tax Rates</Typography>
  //       <RHFMultiCheckbox
  //         sx={{ display: 'flex', flexDirection: 'column' }}
  //         row
  //         spacing={1}
  //         name="taxRates"
  //         options={updatedTaxRates}
  //       />
  //     </Stack>
  //     <Divider />
  //     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
  //       <Box>
  //         <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
  //           Calculated price with tax included
  //         </Typography>
  //         <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
  //           Tax is included in this items price.
  //         </Typography>
  //       </Box>
  //       <RHFSwitch name="calculatedPrice" label={null} sx={{ m: 0 }} />
  //     </Box>
  //     <Divider />
  //     <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
  //       <Box>
  //         <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
  //           Take out exception
  //         </Typography>
  //         <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
  //           This item is taxed except when it is ordered for takeout.
  //         </Typography>
  //       </Box>
  //       <RHFSwitch name="taxException" label={null} sx={{ m: 0 }} />
  //     </Box>
  //   </Stack>
  // );
  const MenuGroups = (
    <Stack
      id="Menu-groups"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px' }}>
        Menu groups
      </Typography>

      {savedgroup?.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 2,
            boxShadow: '0px 10px 5px rgba(0, 167, 111, 1)',
            border: 1,
            borderRadius: '8px',
            my: 2,
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '14px', fontWeight: '600px' }}>
              {item.groupName}
            </Typography>
            <Typography sx={{ color: '#637381', fontSize: '14px', fontWeight: '400px' }}>
              {item.kitchenNickName}
            </Typography>
          </Box>
          {/* <Iconify
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setOpenEditGroupItem(item);
            }}
            icon="eva:more-vertical-fill"
            sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
          /> */}

          <Box>
            <Iconify
              onClick={(event) => {
                OpenEditGroup(item);
                // setselectedObject(item);
                // drawerItem(item);
              }}
              icon="eva:edit-outline"
              sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
            />
            <Iconify
              onClick={(event) => {
                deleteFunc(item);
              }}
              icon="eva:trash-2-outline"
              sx={{ xs: 10, sm: 20, ml: '2px', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      ))}

      <Button
        variant="outlined"
        color="success"
        sx={{ width: '200px', mt: 2 }}
        onClick={() => openGroup()}
      >
        <Box sx={{ mr: 1 }}>+</Box> Add another group
      </Button>
      <Stack spacing={1.5} sx={{ my: 2, width: '80%' }}>
        <Container>
          <Autocomplete
            multiple
            id="tags-standard"
            options={ItemList || []}
            getOptionLabel={(option) => option?.groupName}
            filterSelectedOptions
            value={selectedModifiers}
            // defaultValue={[top100Films[13]]}
            onChange={(event, list, reason, detail) => {
              handleAutocompleteChange2(event, list, reason, detail);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Add from existing Items"
                // placeholder="Favorites"
              />
            )}
          />
        </Container>
      </Stack>
    </Stack>
  );
  const KitchenPrep = (
    <Stack
      id="Kitchen-prep"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px' }}>
        Kitchen prep
      </Typography>
      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography>Kitchen nickname (if different than item name)</Typography>
        <RHFTextField name="kitchenNickName" />
      </Stack> */}

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography> KDS color</Typography>
        <Controller
          name="kdsColor"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={COLOR_OPTIONS}
            />
          )}
        />
      </Stack> */}

      <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Prep Stations</Typography>
        <Typography>
          Items ordered from this menu are routed to the kitchens you select below
        </Typography>
        <RHFMultiCheckbox
          sx={{ display: 'flex', flexDirection: 'column' }}
          row
          spacing={1}
          name="prepStations"
          options={updatedPrepStations}
        />
      </Stack>

      {/* <Stack spacing={1.5} sx={{ my: 2 }}>
        <Typography sx={{ fontSize: '16px' }}>Course</Typography>
        <Typography sx={{ fontSize: '12px', color: '#919EABA1' }}>
          Determine the order in which the items are sent to the kithcen
        </Typography>
        <RHFSelect native name="course" InputLabelProps={{ shrink: true }}>
          {COURSES?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Stack> */}
    </Stack>
  );
  const ChannelVisibility = (
    <Stack
      id="Channel-Visibility"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, my: 6, boxShadow: 3 }}
    >
      <Typography sx={{ fontSize: '16px', color: `grey.500`, fontWeight: '600px', my: 2 }}>
        Channel Visibility
      </Typography>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              POS
            </Typography>
          </Box>
          <RHFSwitch name="pos" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', my: 2 }}>
          <Box>
            <Typography
              sx={{
                color: 'black',
                fontSize: '12px',
                fontWeight: '600px',
                alignItems: 'center',
              }}
            >
              KIOSK
            </Typography>
          </Box>

          <RHFSwitch name="kiosk" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
      <Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            my: 2,
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: '12px', fontWeight: '600px' }}>
              Online Ordering
            </Typography>
          </Box>

          <RHFSwitch name="onlineOrder" label={null} sx={{ m: 0 }} />
        </Box>
        <Divider />
      </Box>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
        // overflowY: 'auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ backgroundColor: '#F5F5F5', height: '80vh' }}>
            <Stack sx={{ pt: 2, backgroundColor: '#F5F5F5' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  mb: 4,
                  px: 10,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify
                    icon="eva:close-fill"
                    sx={{
                      width: '30px',
                      height: '30px',
                      pl: '-2rem',
                      cursor: 'pointer',
                    }}
                    onClick={handleCloseDrawer}
                  />
                  <Typography sx={{ fontSize: '32px', lineHeight: '48px', fontWeight: '700px' }}>
                    Main Menu
                  </Typography>
                </Box>

                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={() => onSubmit()}
                  disabled={loading}
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>
              </Box>

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 14,
                }}
              >
                {/* {breadcrumb && <CustomBreadcrumbs links={breadcrumb} sx={{ pb: 5 }} />} */}
              </Box>
            </Stack>

            <Scrollbar
              sx={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Stack sx={{ position: 'fixed', top: '200px' }}>
                    {FORMDETAILS?.map((item, index) => (
                      <Box
                        sx={{
                          fontSize: '14px',
                          fontWeight: '600',
                          color: '#8E8E8E',
                          borderLeft: 4,
                          borderColor: 'gray',
                          px: '8px',
                          py: '8px',
                          '&:hover': {
                            color: `${palette.primary.main}`,
                            borderColor: `${palette.primary.main}`,
                          },
                          cursor: 'pointer',
                        }}
                        key={index}
                        onClick={() => scrollToSection(item)}
                      >
                        {item}
                      </Box>
                    ))}
                  </Stack>
                </Grid>
                <Grid
                  item
                  ref={createMenuRef}
                  sm={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Stack sx={{ width: '90%' }}>
                      {renderInfo}
                      {Availability}
                      {/* {PricingTaxes} */}
                      {MenuGroups}
                      {KitchenPrep}
                      {ChannelVisibility}
                    </Stack>
                  </Container>
                </Grid>
              </Grid>
            </Scrollbar>
          </Box>
        </FormProvider>
      </Stack>
      <Drawer
        open={openGroupDrawer}
        anchor="right"
        onClose={handleCloseGroupDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddGroup
          breadcrumb={breadcrumblist}
          flow={menuFlow}
          closeFunc={handleCloseGroupDrawer2}
          showgroup={showGroupOption}
          groupItem={selectedObjectRef?.current}
          edit={editOption}
        />
      </Drawer>
    </Box>
  );
};

CreateMenu.propTypes = {
  currentMenu: PropTypes.object,
  pathname: PropTypes.string,
  drawerstatus: PropTypes.bool,
  closeMenu: PropTypes.func,
  breadcrumb: PropTypes.array,
  Menuprops: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
};

export default memo(CreateMenu);
