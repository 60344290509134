import PropTypes from 'prop-types';
import { Typography, Box, Container, Stack } from '@mui/material';
import React, { memo, useMemo, useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Scrollbar from 'src/components/scrollbar';
import { OPTIONTYPE, REQUIRED } from 'src/_mock/_availability';

import { createImage, createModifier, deleteModifier, editModifier } from 'src/api/apis';
import {
  deleteAModifier,
  replaceModifier,
  replaceMyItem,
  saveModifier,
} from 'src/features/group/groupSlice';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from '../iconify';
import { RHFSelect, RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/form-provider';
import DynamicItems from './dynamic-items';
// import AddItem from '../add-item/add-item';

const AddModifier = ({
  currentMenu,
  pathname,
  createMenuRef,
  closeFunc,
  groupItem,
  edit,
  flow,
  showgroup,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [showOptions, setshowOptions] = useState(showgroup);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const savedmodifiers = useSelector((state) => state.group.savedmodifiers);
  const dispatch = useDispatch();

  const MenuSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    optionType: Yup.number().required('option type is required'),
    // nickName: Yup.string(),
    required: Yup.bool(),
    requireQuantity: Yup.bool(),
    // kitchenNickname: Yup.string(),
    // kdsColor: Yup.string(),
    minimumSelection: Yup.number().min(0, 'Choose at least one positive number'),
    // course: Yup.string(),
    maximumSelection: Yup.number()
      .required()
      .when('minimumSelection', (minimumSelection, schema) =>
        schema.moreThan(
          minimumSelection,
          'Maximum selection must be greater than minimum selection'
        )
      ),
  });

  const defaultValues = useMemo(() => {
    if (edit && groupItem) {
      return {
        name: groupItem?.name || '',
        optionType: groupItem?.optionType || 0,
        required: groupItem?.required || false,
        minimumSelection: groupItem?.minimumSelection || 0,
        maximumSelection: groupItem?.maximumSelection || 0,
        requireQuantity: groupItem?.requireQuantity || true,
        options: groupItem?.options || [
          {
            name: '',
            price: 0,
            image: null,
          },
        ],
      };
      // eslint-disable-next-line no-else-return
    } else {
      return {
        name: currentMenu?.name || '',
        optionType: currentMenu?.optionType || 0,
        required: currentMenu?.required || false,
        minimumSelection: currentMenu?.minimumSelection || 0,
        maximumSelection: currentMenu?.maximumSelection || 0,
        requireQuantity: groupItem?.requireQuantity || true,
        options: currentMenu?.options || [
          {
            name: '',
            price: 0,
            image: null,
          },
        ],
      };
    }
  }, [edit, currentMenu, groupItem]);

  const methods = useForm({
    resolver: yupResolver(MenuSchema),
    defaultValues,
  });

  const { handleSubmit, reset, setValue, watch } = methods;
  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    if (edit && groupItem?.menuItems) {
      dispatch(replaceMyItem(groupItem?.menuItems));
    }
  }, [reset, defaultValues, flow, dispatch, groupItem, edit]);

  useEffect(() => {
    if (values.optionType === 1) {
      setValue('minimumSelection', 1);
      setValue('maximumSelection', 1);
      setValue('required', true);
    }
  }, [values.optionType, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    if (loading) return;
    setLoading(true);
    // if (
    //   data.optionType === 1 &&
    //   // data.required === false &&
    //   data.minimumSelection > 1 &&
    //   data.maximumSelection > 1
    // ) {
    //   enqueueSnackbar(
    //     'for option type Radio buttons Required should be set to yes & minimum=1 maximum selection=1',
    //     { variant: 'error' }
    //   );
    //   setLoading(false);
    //   return;
    // }
    // if (data.optionType === 1 && data.required === false) {
    //   enqueueSnackbar('for option type Radio buttons Required should be set to yes', {
    //     variant: 'error',
    //   });
    //   setLoading(false);
    //   return;
    // }
    if (data.optionType === 1 && data.minimumSelection > 1) {
      enqueueSnackbar('for option type Radio buttons minimum selection should be set to 1', {
        variant: 'error',
      });
      setLoading(false);
      return;
    }
    if (data.optionType === 1 && data.maximumSelection > 1) {
      enqueueSnackbar('for option type Radio buttons maximum selection should be set to 1', {
        variant: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      let res;
      let mydata = data;

      if (data.options && data.options.length > 0) {
        const myoptions = await Promise.all(
          data.options.map(async (item, index) => {
            let myimg = item.image; // Use the existing image object by default
            if (item.image instanceof File) {
              const response = await fetch(item.image.preview);
              const blob = await response.blob();
              const Filename = extractFileName(item.image.path);
              const imgRes = await createImage(blob, Filename);
              myimg = imgRes.data[0];
            } else if (item.image === null) {
              myimg = {};
            }
            return {
              name: item.name,
              price: item.price,
              image: myimg,
            };
          })
        );
        mydata = { ...mydata, options: myoptions };
      } else {
        mydata = data;
      }
      if (edit && groupItem.modifierId) {
        res = await editModifier(groupItem.modifierId, mydata);
      } else if (edit === false) {
        res = await createModifier(mydata);
      }
      reset();
      if (res.status === 200) {
        enqueueSnackbar('changes successfully added!', { variant: 'success' });
        if (edit) {
          // eslint-disable-next-line no-unused-vars
          const editedItem = savedmodifiers.map((item) => {
            if (item.modifierId === res.data.modifierId) {
              return res.data;
            }
            return item;
          });
          dispatch(replaceModifier(res.data));
        }
        if (!edit) {
          dispatch(saveModifier(mydata));
        }
        reset();
      } else if (res.status !== 200 || res.status === 404) {
        setLoading(false);
        enqueueSnackbar('Unable to save the changes', { variant: 'error' });
        setLoading(false);
      }
      handeSaveClose();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    setLoading(false);
  });

  // const [filteredItems, setfilteredItems] = useState(optionItems);
  // useEffect(() => {
  //   // Create a set of menuItemIds from savedgroup for quick lookup
  //   if (groupItem !== null && groupItem !== undefined) {
  //     const menuItemIds = new Set(groupItem.menuItems.map((item) => item.menuItemId));
  //     console.log('opop', menuItemIds);
  //     // Filter optionItems to exclude those that are already in savedgroup
  //     // const myfilteredItems = optionItems.filter((item) => !menuItemIds.has(item.menuItemId));
  //     // setfilteredItems(myfilteredItems);
  //     setSelectedOptions(groupItem.menuItems);
  //     console.log('selectedOptions', selectedOptions);
  //   }
  // }, [groupItem, optionItems, savedgroup, selectedOptions]);

  // PopOver
  const handleClose = useCallback(() => {
    closeFunc(false);
  }, [closeFunc]);

  const deleteMenuCallback = useCallback(async () => {
    try {
      const res = await deleteModifier(groupItem.modifierId);
      if (res.status === 200) {
        dispatch(deleteAModifier(groupItem));
      }
      handleClose();
    } catch (error) {
      console.error(`Failed to delete menu with id ${groupItem.modifierId}:`, error);
    }
  }, [dispatch, groupItem, handleClose]);

  const handeSaveClose = useCallback(() => {
    setTimeout(() => {
      closeFunc(false);
    }, 100);
  }, [closeFunc]);

  // file functions
  // file functions
  // const handleDrop = useCallback(
  //   (acceptedFiles, index) => {
  //     const file = acceptedFiles[0];
  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });
  //     const newRows = [...rows];
  //     newRows[index].image = newFile;
  //     setRows(newRows);
  //   },
  //   [rows]
  // );

  // const handleRemoveFile = useCallback(
  //   (index) => {
  //     const newRows = [...rows];
  //     newRows[index].image = null;
  //     setRows(newRows);
  //   },
  //   [rows]
  // );

  // const handleRemoveAllFiles = useCallback(() => {
  //   // setValue('image', []);
  //   console.log('ddd');
  // }, []);

  // console.log('inside menu item', 'flow', flow, 'edit', edit, 'drawerItem', drawerItem);

  function extractFileName(s) {
    const dotIndex = s.indexOf('.');
    return dotIndex !== -1 ? s.substring(0, dotIndex) : s;
  }

  /// container functions
  const renderInfo = (
    <Stack
      id="General-Info"
      sx={{ backgroundColor: 'white', width: '100%', padding: 2, boxShadow: 3 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: '#8A8989', fontWeight: '600px' }}>
          General Info{' '}
        </Typography>
      </Box>

      <Box
        spacing={1.5}
        sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography sx={{ mx: 1 }}>Name</Typography>
        <RHFTextField sx={{ width: '85%', minWidth: '300px' }} name="name" label="Name" />
      </Box>
      <Box
        spacing={1.5}
        sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography sx={{ mx: 1 }}>Option type</Typography>
        <RHFSelect
          native
          name="optionType"
          InputLabelProps={{ shrink: true }}
          sx={{ width: '85%', minWidth: '300px' }}
        >
          {OPTIONTYPE?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Box>

      {/* eslint-disable-next-line eqeqeq */}
      {values.optionType == 0 && (
        <Box
          spacing={1.5}
          sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Typography sx={{ mx: 1 }}>Require Quantity</Typography>
          <RHFSelect
            native
            name="requireQuantity"
            InputLabelProps={{ shrink: true }}
            sx={{ width: '85%', minWidth: '300px' }}
          >
            {REQUIRED?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </RHFSelect>
        </Box>
      )}
      <Box
        spacing={1.5}
        sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography sx={{ mx: 1 }}>Required</Typography>
        <RHFSelect
          native
          name="required"
          // label="Required"
          InputLabelProps={{ shrink: true }}
          sx={{ width: '85%', minWidth: '300px' }}
        >
          {REQUIRED?.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </RHFSelect>
      </Box>
      <Box
        spacing={1.5}
        sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography sx={{ mx: 1 }}>Minimum Selection</Typography>
        <RHFTextField
          name="minimumSelection"
          label="Enter number minimum at least option required"
          type="number"
        />
      </Box>
      <Box
        spacing={1.5}
        sx={{ py: 'auto', my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Typography sx={{ mx: 1 }}>Maximum Selection</Typography>
        <RHFTextField
          name="maximumSelection"
          label="Enter number maximum option can select"
          type="number"
        />
      </Box>
      <DynamicItems />
    </Stack>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'start',
        backgroundColor: '#F5F5F5',
        // overflowY: 'auto',
      }}
    >
      <Stack sx={{ width: '100%' }}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ backgroundColor: '#F5F5F5', height: '80vh' }}>
            <Stack sx={{ pt: 2, backgroundColor: '#F5F5F5' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 4,
                  px: 10,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify
                    icon="eva:close-fill"
                    sx={{
                      width: '30px',
                      height: '30px',
                      pl: '-2rem',
                      cursor: 'pointer',
                    }}
                    onClick={handleClose}
                  />
                  <Typography sx={{ fontSize: '32px', lineHeight: '48px', fontWeight: '700px' }}>
                    Modifier
                  </Typography>
                </Box>

                <Box>
                  {/* <Button
                    size="large"
                    color="error"
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    sx={{ mx: 2 }}
                    onClick={() => deleteMenuCallback()}
                  >
                    Delete
                  </Button> */}
                  <Button
                    size="large"
                    color="success"
                    variant="contained"
                    target="_blank"
                    rel="noopener"
                    onClick={onSubmit}
                    disabled={loading}
                    sx={{ mr: 2 }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Stack>

            <Scrollbar
              sx={{ height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}
            >
              <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Box />
                </Grid>
                <Grid
                  // ref={addGroupRef}
                  sm={8}
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Container>
                    <Stack sx={{ width: '90%' }}>{renderInfo}</Stack>
                  </Container>
                </Grid>
              </Grid>
            </Scrollbar>
          </Box>
        </FormProvider>
      </Stack>
    </Box>
  );
};

AddModifier.propTypes = {
  currentMenu: PropTypes.object,
  pathname: PropTypes.string,
  createMenuRef: PropTypes.object,
  closeFunc: PropTypes.func,
  groupItem: PropTypes.object,
  edit: PropTypes.bool,
  flow: PropTypes.bool,
  showgroup: PropTypes.bool,
};

export default memo(AddModifier);
