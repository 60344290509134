// @mui
import Container from '@mui/material/Container';
import SetupSection from 'src/components/setup-menu';

// ----------------------------------------------------------------------

export default function MenuSetupView() {
  return (
    <Container>
      <SetupSection />
    </Container>
  );
}
