import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useRef, useCallback } from 'react';
import { Box, Drawer } from '@mui/material';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDispatch } from 'react-redux';
import { mutate } from 'swr';
import { endpoints } from 'src/utils/axios';
import { removeAddGroup } from 'src/features/group/groupSlice';
import Iconify from '../iconify/iconify';
import AddItem from '../add-item/add-item';
import AddGroup from '../add-group';
import AddModifier from '../add-modifier/add-modifier';
import CreateMenu from '../create-menu';

const breadcrumblist = [
  { name: 'Home', href: './' },
  {
    name: 'Menu Builder',
    href: './',
  },
  {
    name: 'Create Item',
    href: './',
  },
];
export default function ModalTable({ list, myitems, menus, groups, items, modifiers }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const [, setmodalOpen] = useState(false);

  const handleModalOpen = () => setmodalOpen(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let tableMenuHeaderList = [];
  let rows;
  if (menus) {
    tableMenuHeaderList = ['SrNo', 'Name', 'Groups', 'Status', 'Action'];
    rows = list?.map((item) =>
      createMenuData(item.menuId, item?.menuName, item.menuGroups, item.availability)
    );
  } else if (groups) {
    tableMenuHeaderList = ['SrNo', 'Name', 'Items', 'Status'];
    rows = list?.map((item) =>
      createGroupData(item.menuGroupId, item?.groupName, item.menuItems, item.availability)
    );
  } else if (items) {
    tableMenuHeaderList = ['SrNo', 'Image', 'Name', 'Price', 'Action'];
    rows = list?.map((item) =>
      createItemData(item.menuItemId, item?.image?.path, item.menuItemName, item.price)
    );
  } else if (modifiers) {
    tableMenuHeaderList = ['SrNo', 'Name', 'Required', 'Action'];
    rows = list?.map((item) => createModifierData(item.modifierId, item.name, item.required));
  }

  function createItemData(menuItemId, image, menuItemName, price, ...item) {
    return { menuItemId, image, menuItemName, price, ...item };
  }
  function createGroupData(menuGroupId, groupName, menuItems, availability, ...item) {
    return { menuGroupId, groupName, menuItems, availability, ...item };
  }
  function createModifierData(modifierId, name, required, ...item) {
    return { modifierId, name, required, ...item };
  }
  function createMenuData(menuId, menuName, menuGroups, required, ...item) {
    return { menuId, menuName, menuGroups, required, ...item };
  }
  // rows = menuItems.map((menuItem) =>
  //   createData(menuItem.menuItemName, `${menuItem.availability}`, menuItem.menuItemId)
  // );
  // const rows = list.map((menuItem) =>
  //   createData(menuItem.menuItemName, `${menuItem.availability}`, menuItem.menuItemId)
  // );

  // Item Drawer
  const [openItemDrawer, setopenItemDrawer] = useState(false);
  const [openGroupDrawer, setopenGroupDrawer] = useState(false);
  const [openMenuDrawer, setopenMenuDrawer] = useState(false);
  const [openModifierDrawer, setopenModifierDrawer] = useState(false);
  const selectedObjectRef = useRef(null);

  const handleCloseItemDrawer = useCallback(() => {
    if (items) {
      setopenItemDrawer(false);
      mutate(endpoints.menuItem.menu);
    } else if (modifiers) {
      setopenModifierDrawer(false);
      mutate(endpoints.modifier.modifiers);
    } else if (groups) {
      setopenGroupDrawer(false);
      mutate(endpoints.menuGroup.menu);
    } else if (menus) {
      setopenMenuDrawer(false);
      mutate(endpoints.menu.menu);
    }
    dispatch(removeAddGroup());
  }, [items, modifiers, groups, menus, dispatch]);

  const handleOpenItemDrawer = useCallback(() => {
    if (items) {
      setopenItemDrawer(true);
    } else if (modifiers) {
      setopenModifierDrawer(true);
    } else if (groups) {
      setopenGroupDrawer(true);
    } else if (menus) {
      setopenMenuDrawer(true);
    }
  }, [items, modifiers, groups, menus]);

  const handleCloseItemDrawer2 = useCallback(
    (val) => {
      if (items) {
        setopenItemDrawer(false);
        mutate(endpoints.menuItem.menu);
      } else if (modifiers) {
        setopenModifierDrawer(false);
        mutate(endpoints.modifier.modifiers);
      } else if (groups) {
        setopenGroupDrawer(false);
        mutate(endpoints.menuGroup.menu);
      } else if (menus) {
        setopenMenuDrawer(false);
        mutate(endpoints.menu.menu);
      }
      dispatch(removeAddGroup());
    },
    [items, modifiers, groups, menus, dispatch]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const filterItem = (myitem) => {
    // setSelectObj(myitem);
    if (myitem !== null) {
      if (items) {
        const selectedobject = list.filter((item) => myitem.menuItemId === item.menuItemId);
        selectedObjectRef.current = selectedobject;
        handleOpenItemDrawer();
        handleClose();
      } else if (groups) {
        const selectedobject = list.filter((item) => myitem.menuGroupId === item.menuGroupId);
        selectedObjectRef.current = selectedobject;
        handleOpenItemDrawer();
        handleClose();
      } else if (modifiers) {
        const selectedobject = list.filter((item) => myitem.modifierId === item.modifierId);
        selectedObjectRef.current = selectedobject;
        handleOpenItemDrawer();
        handleClose();
      } else if (menus) {
        const selectedobject = list.filter((item) => myitem.menuId === item.menuId);
        selectedObjectRef.current = selectedobject;
        handleOpenItemDrawer();
        handleClose();
      }
    }
  };

  // popover
  // function saveobj(event, item) {
  //   setSelectObj(item);
  //   // setAnchorEl(event.currentTarget);
  // }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableMenuHeaderList?.map((heading) => (
                  <TableCell align="center" colSpan={1}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.menuItemId}
                  sx={{ cursor: 'pointer' }}
                >
                  {/* Display only the desired attributes */}
                  {menus && (
                    <>
                      <TableCell align="center">{row.menuId}</TableCell>
                      <TableCell align="center">{row.menuName}</TableCell>
                      <TableCell align="center" onClick={handleModalOpen} sx={{ fontSize: '12px' }}>
                        {row.menuGroups?.map((item) => item.groupName).join(', ')}
                      </TableCell>
                      <TableCell align="center">{`${row.required}`}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ height: '100%', marginTop: '1rem' }}>
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:edit-outline"
                            onClick={(event) => {
                              // saveobj(event, row);
                              filterItem(row);
                            }}
                          />
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:trash-2-outline"
                          />
                        </Box>
                      </TableCell>
                    </>
                  )}
                  {groups && (
                    <>
                      <TableCell align="center">{row.menuGroupId}</TableCell>
                      <TableCell align="center">{row.groupName}</TableCell>
                      {/* <TableCell align="center">{row.menuItems}</TableCell> */}
                      <TableCell align="center" sx={{ fontSize: '12px' }}>
                        {row.menuItems?.map((item) => item.menuItemName)}
                      </TableCell>
                      <TableCell align="center">{`${row.availability}`}</TableCell>
                      <TableCell align="center">
                        {/* <Box sx={{ height: '100%', marginTop: '1rem' }}>
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:edit-outline"
                            onClick={(event) => {
                              // saveobj(event, row);
                              filterItem(row);
                            }}
                          />
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:trash-2-outline"
                          />
                        </Box> */}
                      </TableCell>
                    </>
                  )}
                  {items && (
                    <>
                      <TableCell align="center">{row.menuItemId}</TableCell>
                      <TableCell align="center">
                        <img src={row.image} alt="" style={{ width: '50px', height: '50px' }} />
                      </TableCell>
                      <TableCell align="center">{row.menuItemName}</TableCell>
                      <TableCell align="center">{row.price}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ height: '100%', marginTop: '1rem' }}>
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:edit-outline"
                            onClick={(event) => {
                              // saveobj(event, row);
                              filterItem(row);
                            }}
                          />
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:trash-2-outline"
                          />
                        </Box>
                      </TableCell>
                    </>
                  )}
                  {modifiers && (
                    <>
                      <TableCell align="center">{row.modifierId}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{`${row.required}`}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ height: '100%', marginTop: '1rem' }}>
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:edit-outline"
                            onClick={(event) => {
                              // saveobj(event, row);
                              filterItem(row);
                            }}
                          />
                          <Iconify
                            width={20}
                            sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                            icon="eva:trash-2-outline"
                          />
                        </Box>
                      </TableCell>
                    </>
                  )}
                  {/* <TableCell colSpan={1} align="center">
                    <Box sx={{ height: '100%', marginTop: '1rem' }}>
                      <Iconify
                        width={20}
                        sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                        icon="eva:edit-outline"
                        onClick={(event) => {
                          saveobj(event, row);
                          filterItem();
                        }}
                      />{' '}
                      <Iconify
                        width={20}
                        sx={{ xs: 10, sm: 20, cursor: 'pointer' }}
                        icon="eva:trash-2-outline"
                      />{' '}
                    </Box>
                  </TableCell> */}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    {row && (
                      <Button
                        variant="subtitle2"
                        nowrap
                        onClick={() => filterItem()}
                        sx={{ cursor: 'pointer' }}
                      >
                        Edit
                      </Button>
                    )}
                  </Popover>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Drawer
        open={openItemDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddItem
          edit
          breadcrumb={breadcrumblist}
          closeItem={handleCloseItemDrawer2}
          drawerItem={selectedObjectRef?.current?.[0]}
        />
      </Drawer>

      <Drawer
        open={openGroupDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddGroup
          groupItem={selectedObjectRef?.current?.[0]}
          breadcrumb={breadcrumblist}
          closeFunc={handleCloseItemDrawer2}
          edit
        />
      </Drawer>

      <Drawer
        open={openModifierDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <AddModifier
          groupItem={selectedObjectRef?.current?.[0]}
          closeFunc={handleCloseItemDrawer2}
          edit
        />
      </Drawer>
      <Drawer
        open={openMenuDrawer}
        anchor="right"
        onClose={handleCloseItemDrawer}
        slotProps={{
          backdrop: { invisible: true },
        }}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: '95%',
            overflowY: 'hidden',
          },
        }}
      >
        <CreateMenu
          Menuprops={selectedObjectRef?.current?.[0]}
          breadcrumb={breadcrumblist}
          closeMenu={handleCloseItemDrawer}
          edit
          // flow={flowOption}
        />
      </Drawer>
    </>
  );
}

ModalTable.propTypes = {
  list: PropTypes.array,
  myitems: PropTypes.object,
  menus: PropTypes.bool,
  groups: PropTypes.bool,
  items: PropTypes.bool,
  modifiers: PropTypes.bool,
};
