import { Helmet } from 'react-helmet-async';
import MenuSetupView from 'src/sections/menu-setup/view/menu-setup-view';

// ----------------------------------------------------------------------

export default function OverviewMenuSetupPage() {
  return (
    <>
      <Helmet>
        <title> Menu Setup</title>
      </Helmet>

      <MenuSetupView />
    </>
  );
}
