/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useGetMenus, useGetAllGroups, useGetAllItems } from 'src/api/apis';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import DropableCategories from './dropable-categories';
import Iconify from '../iconify';

export default function SetupSection({ OpenMenu }) {
  // const { data: menu } = useGetMenus({ search: '' });
  const { data: menu } = useGetMenus();
  const { data: groups } = useGetAllGroups();
  const { data: optionItems } = useGetAllItems();

  console.log('data', menu);

  const [menus, setMenus] = useState([]);
  const [group, setGroups] = useState([]);
  const [items, setItem] = useState([]);
  const [selectedOption, setselectedOption] = useState({});
  const [type, setType] = useState();

  useEffect(() => {
    if (menu) {
      setMenus(menu);
    }
    if (groups) {
      setGroups(groups);
    }
    if (optionItems) {
      setItem(items);
    }
  }, [groups, items, menu, optionItems]);

  function selectOption(object, optionType) {
    setselectedOption(object);
    setType(optionType);
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
        <DropableCategories menuProps={selectedOption} type={type} />
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: '25px',
            display: 'flex',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography> AlL Menus</Typography>
            <Box>
              {menus.map((mymenu, i) => (
                <Button
                  sx={{ ml: 1 }}
                  size="medium"
                  color="primary"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  onClick={() => selectOption(mymenu, 'Menu')}
                >
                  {mymenu.menuName}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography> AlL Categories</Typography>
            <Box>
              {group.map((mygroup, i) => (
                <Button
                  sx={{ ml: 1, mt: 1 }}
                  size="medium"
                  color="primary"
                  variant="contained"
                  target="_blank"
                  rel="noopener"
                  // onClick={handleOpenItemDrawer}
                >
                  {mygroup.groupName}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

SetupSection.propTypes = {
  OpenMenu: PropTypes.func,
};
