// @mui
import Container from '@mui/material/Container';
import ModifierSection from '../../../components/modifier-section/modifier-section';
// ----------------------------------------------------------------------

export default function ModifiersView() {
  return (
    <Container>
      <ModifierSection />
    </Container>
  );
}
