import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useGetMenus } from 'src/api/apis';
import Iconify from '../iconify';

export default function DropableCategories({ menuProps, type }) {
  // const { data: menu } = useGetMenus({ search: '' });
  const { data: menu } = useGetMenus();

  console.log('menuPropsssssssssssss', menuProps, type);

  const [items, setItems] = useState();
  console.log('data in cat', items);

  useEffect(() => {
    setItems(menuProps.menuGroups);
  }, [menuProps]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    console.log('result', result);

    // If dropped outside the list or in the same position
    if (!destination || source.index === destination.index) {
      return;
    }
    const updatedItems = Array.from(items);
    const [movedItem] = updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, movedItem);

    setItems(updatedItems);
  };

  const handleSave = () => {};

  return (
    <Box>
      <Box
        sx={{ my: 3, width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        <Button
          size="medium"
          color="primary"
          variant="contained"
          target="_blank"
          rel="noopener"
          // onClick={handleOpenGroupDrawer}
        >
          Save
        </Button>
      </Box>
      <DragDropContext sx={{ width: '100%' }} onDragEnd={onDragEnd}>
        <Droppable sx={{ width: '100%' }} droppableId="droppable">
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={{
                padding: '10px',
                maxWidth: '400px',
                margin: 'auto',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              <Accordion defaultExpanded key={menuProps?.menuId || menuProps?.menuGroupId}>
                <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                  <Typography variant="subtitle1">{menuProps?.menuName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {items?.map((group, groupIndex) => (
                    <Draggable
                      key={group.menuGroupId}
                      draggableId={`${group.menuGroupId}`}
                      index={groupIndex}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            padding: '16px',
                            margin: '4px 0',
                            backgroundColor: '#f4f4f4',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            ...draggableProvided.draggableProps.style,
                          }}
                        >
                          <Accordion key={group.menuGroupId}>
                            <AccordionSummary
                              expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                            >
                              <Typography variant="subtitle1">{group.groupName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>{group.groupName}</Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </AccordionDetails>
              </Accordion>
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

DropableCategories.propTypes = {
  menuProps: PropTypes.object,
  type: PropTypes.string,
};
